@import 'mantine';
.scriptBlock {
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: linear-gradient(
      to bottom right,
      #614b9f,
      var(--mantine-color-blue-7) 20%
    ),
    linear-gradient(to top, var(--mantine-color-blue-7) 50%, transparent 50%);
  padding-top: rem(40);
  padding-bottom: rem(40);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    background: linear-gradient(
        to right,
        var(--mantine-color-blue-7) 50%,
        transparent 50%
      ),
      linear-gradient(to bottom right, #614b9f, var(--mantine-color-blue-7) 60%);
    padding-top: rem(120);
    padding-bottom: initial;
  }
}

.serviceName {
  color: var(--mantine-color-gray-6);
}

.scriptContent {
  color: var(--mantine-color-gray-0);
  padding-top: rem(24);
  padding-bottom: rem(24);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding-top: 0;
    padding-bottom: rem(120);
  }
}

.scriptCode {
  width: 100%;
  mask-image: linear-gradient(
    to bottom,
    rgba(0 0 0 / 100%) 60%,
    rgba(0 0 0 / 0%)
  );
  font-family: var(--font-basically-a-mono), sans-serif;
  padding-top: 0;
  max-height: rem(280);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    max-height: rem(500);
  }
}

.codeLine {
  color: var(--mantine-color-gray-6);
  display: block;
  font-size: rem(11);
}

.codeBlock {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: fit-content;
  color: var(--mantine-color-gray-6);
  font-size: rem(11);
  line-height: rem(18);
}

.highlightedCode {
  color: var(--mantine-color-yellow-0);
}

.ident {
  padding-left: rem(16);
}

.doubleIdent {
  padding-left: rem(32);
}

.tripleIdent {
  padding-left: rem(48);
}
