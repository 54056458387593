@use 'sass:math';
@import 'text';

// Define variables for your breakpoints,
// values must be the same as in your theme
$mantine-breakpoint-mobile: '22.5em'; // 360 px
$mantine-breakpoint-mobile-lg: '30em'; // 480 px
$mantine-breakpoint-tablet: '47.5em'; // 760 px
$mantine-breakpoint-desktop-sm: '64em'; // 1024 px
$mantine-breakpoint-desktop: '80em'; // 1280 px
$mantine-breakpoint-desktop-lg: '100em'; // 1600 px

@function rem($value) {
  @return #{math.div(math.div($value, $value * 0 + 1), 16)}rem;
}

@mixin light {
  [data-mantine-color-scheme='light'] & {
    @content;
  }
}

@mixin dark {
  [data-mantine-color-scheme='dark'] & {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }

  @media (hover: none) {
    &:active {
      @content;
    }
  }
}

@mixin smaller-than($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin larger-than($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

// Add direction mixins if you need rtl support
@mixin rtl {
  [dir='rtl'] & {
    @content;
  }
}

@mixin ltr {
  [dir='ltr'] & {
    @content;
  }
}

@mixin elevation-1 {
  box-shadow: 0px 0px 2px 1px rgba(8, 13, 29, 0.05);
  box-shadow: 0px 8px 8px -4px rgba(8, 13, 29, 0.1);
}

@mixin elevation-3 {
  box-shadow: 0px 0px 16px 1px rgba(8, 13, 29, 0.1);
  box-shadow: 0px 24px 24px -2px rgba(8, 13, 29, 0.5);
}

/// Mixin to place items on a circle
/// @param {Length} $circle-size - Large circle size
/// @param {Angle} $angle-covert - Total angle to covert (default 360deg)
/// @param {Angle} $first-element-pos - Angle of the first element (default 0deg)
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $item-size - Single item size
@mixin on-circle(
  $circle-size,
  $angle-covert: 360,
  $first-element-pos: 0,
  $item-count,
  $item-size
) {
  width: $circle-size;
  height: $circle-size;
  padding: 0;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: calc($item-size / 2 * -1);

    $angle: ($angle-covert / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate(($rot * 1deg + $first-element-pos))
          translate($circle-size / 2);
      }

      $rot: $rot + $angle;
    }
  }
}

@mixin full-width-on-mobile {
  margin-inline: 0;

  @include larger-than(408px) {
    margin-inline: auto;
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    max-width: rem(1216);
    margin-inline: rem(32);
  }

  @include larger-than($mantine-breakpoint-desktop) {
    margin-inline: auto;
  }
}
